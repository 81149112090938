<template>
  <div class="inviteFriends">
    <mt-header :title="$t('inviteFriends')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <div class="box">
        <div class="title">
          <img src="../../assets/link.png" width="20px">
          {{ $t('invitationAddress') }}
        </div>
        <div class="value link">
          <div class="url">{{ url }}</div>
          <mt-button @click="onCopy" class="copy" size="small" plain>{{ $t('copy') }}</mt-button>
        </div>
      </div>
      <div class="box">
        <div class="title" @click="$router.push(`/mine/qrCode?url=${url}`)">
          <img src="../../assets/code.png" width="20px">
          {{ $t('exclusiveInvitationQrCode') }}
          <img class="arrow" src="../../assets/arrow-r.png" width="10px">
        </div>
      </div>
      <div class="box">
        <div class="title">
          <img src="../../assets/steps.png" width="20px">
          {{ $t('inviteFriendsStep') }}
        </div>
        <div class="value step">
          <div class="step_item">
            {{ $t('inviteFriendsSteptips1') }}
          </div>
          <div class="step_item">
            {{ $t('inviteFriendsSteptips2') }}
          </div>
          <div class="step_item">
            {{ $t('inviteFriendsSteptips3') }}
          </div>
        </div>
      </div>
      <div class="box">
        <div class="title">
          <img src="../../assets/activityTime.png" width="20px">
          {{ $t('activityTime') }}
        </div>
        <div class="value activityTime">
          {{ $t('activityTimeTips') }}
        </div>
      </div>
      <div class="box">
        <div class="title">
          <img src="../../assets/activityRules.png" width="20px">
          {{ $t('activityRules') }}
        </div>
        <div class="value rules">
          <div>{{ $t('activityRulesList1') }}</div>
          <div>{{ $t('activityRulesList2') }}</div>
          <div>{{ $t('activityRulesList3') }}</div>
          <div>{{ $t('activityRulesList4') }}</div>
          <div>{{ $t('activityRulesList5') }}</div>
          <div>{{ $t('activityRulesList6') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import callPhone from '@/components/callPhone.vue'
import { getRecommendUrl } from '../../utils/api'
export default {
  name: 'InviteFriends',
  components: {
    callPhone
  },
  data() {
    return {
      url: ''
    }
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      await this.getRecommendUrl()
      this.$indicator.close();
      this.$NProgress.done()
    },
    async getRecommendUrl() {
      await this.$axios({
        method: "get",
        url: getRecommendUrl,
        params: {
          idcard: this.user.idcard,
        }
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.url = e.body
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
    },
    onCopy() {
      let transfer = document.createElement('input');
      document.body.appendChild(transfer);
      transfer.readonly = 'readonly';
      transfer.value = this.url;
      transfer.focus();
      transfer.select();
      if (document.execCommand('copy')) {
          document.execCommand('copy');
      }
      transfer.blur();
      this.$toast(this.$t('copySuccess'));
      document.body.removeChild(transfer);
    }
  }
}
</script>

<style lang="scss" scoped>
.inviteFriends {
  background-color: #F7F7F7;
  .content {
    .box {
      position: relative;
      margin-top: 10px;
      background-color: white;
      border-radius: 0px 0px 10px 10px;
      padding: 20px;
      font-size: 14px;
      .title {
        font-size: 16px;
        font-weight: 600;
        img {
          margin-right: 6px;
          vertical-align: sub;
        }
        .arrow {
          float: right;
          margin-top: 4px;
        }
      }
      .value {
        margin-top: 20px;
      }
    }
    .link {
      display: flex;
      .url {
        flex: 1;
        font-size: 12px;
      }
      .copy {
        border: 1px solid #939393;
        border-radius: 20px;
        height: 22px;
        font-size: 12px;
      }
    }
    .step {
      .step_item {
        position: relative;
        padding-bottom: 25px;
        font-size: 15px;
        &:last-child {
          padding-bottom: 0px;
        }
        @for $i from 1 through 3 {
          &:nth-child(#{$i})::before {
            content: '#{$i}';
          }
        }
        &::before {
          content: '';
          display: inline-block;
          z-index: 1;
          height: 33px;
          width: 33px;
          line-height: 33px;
          background-color: #4F9B41;
          color: white;
          border-radius: 33px;
          text-align: center;
          margin-right: 20px;
          margin-left: 25px;
        }
      }
    }
    .activityTime {
      text-align: center;
      font-size: 15px;
      color: #212121;
    }
    .rules {
      margin-left: 30px;
    }
  }
}
</style>